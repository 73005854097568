import React, { useEffect, useState } from 'react'
import Product from '../../models/Product'
import { Modal } from 'react-bootstrap'
import ProductImg from '../ProductImg'
import { useCart } from '../../contexts/CartContext'
import { areAllMandatoryChoosen, productPrice } from '../../utils/shared/cart'
import { printAmount } from '../../utils/shared/converters'
import { ProductOptionInputMandatory, ProductOptionInputOptional } from '../customer/ProductOptionInput'
import RichEditor from '../RichEditor'
import { AdvancedButton } from '../AdvancedButton'
import { useText } from '../../contexts/TextContext'

interface Props {
    product: Product
    onClose: () => void
}

function ProductModal(props: Props) {
    const { product } = props
    const { adjustCount } = useCart();
    const [choosen, setChoosen] = useState<string[]>([])
    const text = useText()

    useEffect(() => {
        const choices = product.options?.map(option => option.choices).flat() || [];
        const defaults = choices
            .filter(choice => !choice.disabled && choice.default)
            .map(choice => choice.id);
        setChoosen(defaults);
    }, [product]);

    const options = (product.options || []).filter(option => !!option.choices?.length);
    const price = productPrice(product, choosen);
    const canAdd = areAllMandatoryChoosen(product, choosen);

    const handleChoiceMandatory = (optionIx: number, choiceId: string) => {
        const optionChoices = product.options[optionIx].choices.map(choice => choice.id);
        const newChoosen = choosen
            .filter(choosenId => !optionChoices.includes(choosenId))
            .concat([choiceId]);

        setChoosen(newChoosen);
    }

    const handleChoiceOptional = (choiceId: string, checked: boolean) => {
        const newChoosen = checked ? choosen.concat([choiceId]) : choosen.filter(choice => choice !== choiceId)

        setChoosen(newChoosen);
    }

    const handleAdd = () => {
        adjustCount(+1, product, choosen)
        props.onClose();
    }

    return <Modal
        centered
        show onHide={props.onClose}
        fullscreen='md-down'
        scrollable
        contentClassName='border rounded-3 bg-background text-primary overflow-hidden'>

        <Modal.Body className='p-0'>
            <div>
                <button className='btn position-absolute btn-lg text-primary p-1 bold bg-background' onClick={props.onClose} style={{ right: 0, top: 0 }} >
                    <i className="bi-x fs-1" />
                </button>
                <ProductImg product={product} className='max-h-50vh overflow-hidden' />
            </div>
            <div className='m-3 mb-0 position-relative'>

                <h3 className='pb-4 pt-2 m-0 position-sticky bg-background'
                    style={{ zIndex: 100, top: 0, left: 0 }}>{product.name}</h3>
                <h5 className='mb-4'>{printAmount(price)}</h5>

                {product.tagline && <h6 className="mb-4">{product.tagline}</h6>}


                {options.map((option, optionIx) => option.mandatory
                    ? <ProductOptionInputMandatory key={optionIx} option={option} choosen={choosen} handleChoice={(choiceIx) => handleChoiceMandatory(optionIx, choiceIx)} />
                    : <ProductOptionInputOptional key={optionIx} option={option} choosen={choosen} handleChoice={handleChoiceOptional} />
                )}

                <RichEditor readOnly={true} raw={product.description} />

                <div className="product-btn-container p-3" style={{ zIndex: 100 }}>
                    <AdvancedButton disabled={!canAdd} onClick={handleAdd} size='lg' variant='success'>{text.addToCart}</AdvancedButton>
                </div>
            </div>
        </Modal.Body>
    </Modal >
}

export default ProductModal
