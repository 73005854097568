import React from 'react';
import App from './App';
import * as Sentry from "@sentry/react";
import config from './utils/config';
import { createRoot } from 'react-dom/client';

if (config.isProduction) {
  Sentry.init({
    integrations: [
      Sentry.replayIntegration(),
    ],
    dsn: "https://d72b98e90dd148b49593e6a6e05fd4e6@o342988.ingest.sentry.io/6044545",
    tracesSampleRate: 0,
    release: "1.0.0",
  });
}

const container = document.getElementById('root')
if (!container) {
  throw new Error('No root element found');
}
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
