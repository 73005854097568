import Menu from '../../components/customer/Menu'
import CheckoutFooter from '../../components/customer/CheckoutFooter';
import { STAFF_NAV } from '../../constants/routes';
import { useCart } from '../../contexts/CartContext';
import Product from '../../models/Product';
import CompanyFooter from '../../components/customer/CompanyFooter';
import { useEffect, useMemo, useState } from 'react';
import { useProducts } from '../../contexts/ProductsContext';
import { useParams } from 'react-router-dom';
import ProductModal from '../../components/modals/ProductModal';
import ProductTile from '../../components/customer/ProductTile';
import { useRestaurantNavigate } from '../../utils/restaurant';

export default function ProductsPage() {
    const { productId } = useParams<{ productId: string }>();
    const productsHolder = useProducts();
    const { cart } = useCart();
    const [collapsedCategories, setCollapsedCategories] = useState<Record<string, boolean>>({})
    const chosenProduct = productsHolder.allProductsById[productId ?? ''];
    const navigate = useRestaurantNavigate();
    const [currentCategory, setCurrentCategory] = useState<string | null>(null);


    const toggleCategory = (category: string) => {
        setCollapsedCategories(prev => ({ ...prev, [category]: !prev[category] }))
    }


    const handleProductTitleClick = (product: Product) => {
        navigate(`/${STAFF_NAV.PRODUCTS}/${product.id}`);
    }


    const handleProductTitleClose = () => navigate('/')

    const navigateToCategory = (category: string) => {
        const element = document.getElementById(category);
        const top = element?.getBoundingClientRect().top
        const categoryHeaderHeight = document.getElementById('category-header')?.getBoundingClientRect().height || 0;

        window.scrollTo({ top: top ? window.scrollY + top - categoryHeaderHeight : 0, behavior: 'smooth' });
    }

    const categories = useMemo(() => productsHolder.categories ?? [], [productsHolder.categories]);

    useEffect(() => {
        const handleScroll = () => {
            let current = null;
            categories.forEach(category => {
                const element = document.getElementById(category);
                if (element) {
                    const rect = element.getBoundingClientRect();
                    const categoryHeaderHeight = document.getElementById('category-header')?.getBoundingClientRect().height || 0;
                    if (rect.top <= categoryHeaderHeight && rect.bottom > categoryHeaderHeight) {
                        current = category;
                    }
                }
            });
            setCurrentCategory(current);
        };

        window.addEventListener('scroll', handleScroll);

        // Initial check
        handleScroll();

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [categories]);


    return (
        <div className='streach'>
            <Menu noBack={true} />

            <div className="container">
                <div className='d-flex gap bg-background py-1 overflow-auto' id="category-header" style={{ position: 'sticky', top: '0px', zIndex: 100 }}>
                    {categories.map(category => <button className={`btn ${currentCategory === category ? 'btn-primary' : 'text-primary'}`} key={category} onClick={() => navigateToCategory(category)}>
                        <p className={`m-0 fs-7 `}>{category}</p>
                    </button>)}
                </div>


                {categories.map(category => {
                    const isCollapsed = collapsedCategories[category] ?? false;
                    return <div key={category} id={category}>
                        <div className='mt-3 d-flex pointer' onClick={() => toggleCategory(category)}>
                            <i className={`me-2 sbi  text-primary ${isCollapsed ? 'bi-caret-right-fill' : 'bi-caret-down-fill'}`} />
                            <h5 className='m-0 text-primary'>{category}</h5>
                        </div>
                        {!isCollapsed && <div className='d-flex flex-wrap'>
                            {productsHolder.productsByCategory[category]?.map(product =>
                                <ProductTile key={product.id} product={product} onClick={handleProductTitleClick} />)}

                        </div>}

                    </div>
                })}
            </div>

            {chosenProduct && <ProductModal onClose={handleProductTitleClose} product={chosenProduct} />}
            <CheckoutFooter cart={cart} />
            <CompanyFooter />
        </div>
    )
}
