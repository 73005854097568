import { onSnapshot } from '@firebase/firestore';
import { doc } from 'firebase/firestore';
import { useEffect, useState } from 'react'
import { Navigate, useParams } from 'react-router-dom';
import Menu from '../../components/customer/Menu'
import { useDB } from '../../contexts/FirebaseContext';
import { useRestaurant } from '../../contexts/RestaurantContext';
import { Order, OrderState } from '../../models/Order';
import { fromFirebaseDoc } from '../../utils/shared/firebase';
import CompanyFooter from '../../components/customer/CompanyFooter';
import { getRestaurantPath } from '../../utils/restaurant';
import { CUSTOMER_NAV } from '../../constants/routes';
import { useText } from '../../contexts/TextContext';
import { Button } from 'react-bootstrap';
import RestaurantLink from '../../components/RestaurantLink';
import { AdvancedButton } from '../../components/AdvancedButton';
import { toast } from 'react-toastify';
import { useBackend } from '../../hooks/useBackend';
import { PAYMENT_TYPES } from '../../utils/shared/constants';

export default function CardProcessingPage() {
    const [order, setOrder] = useState<Order | null>(null);
    const { orderId } = useParams<{ orderId: string }>();
    const [processing, setProcessing] = useState(false);
    const [showWarning, setShowWarning] = useState(false);
    const { paymentCallback } = useBackend();
    const db = useDB();
    const restaurant = useRestaurant();
    const texts = useText();

    const orderState = order?.state || OrderState.NEW;

    useEffect(() => {
        if (db && orderId && restaurant.id) {
            return onSnapshot(doc(db, "restaurants", restaurant.id, "orders", orderId), (doc) => {
                const newOrder = fromFirebaseDoc<Order>(doc)
                setOrder(newOrder);
            });
        }
    }, [db, orderId, restaurant.id]);

    const handleOrderCheck = async () => {
        setProcessing(true);
        try {
            await paymentCallback(order?.id || '', order?.paymentType || PAYMENT_TYPES.STRIPE_TERMINAL, restaurant.sysid)
            setTimeout(() => setShowWarning(true), 1000);

        } catch (e) {
            console.error('Error in handleOrderCheck', e);
            toast.error(texts.weFailedToVerify);
        }
        setProcessing(false);
    }

    const supportedStates = [OrderState.NEW, OrderState.FAILED];
    if (!supportedStates.includes(orderState)) {
        return <Navigate to={getRestaurantPath(restaurant, `/${CUSTOMER_NAV.CONFIRM}/${orderId}`)} />
    }

    const renderContent = () => {
        if (orderState === OrderState.FAILED) {
            return <div className='center flex-column'>
                <h3><i className='bi bi-exclamation-diamond-fill text-danger' /> {texts.paymentFailed}</h3>

                <RestaurantLink to={`/${CUSTOMER_NAV.ORDER}`}>
                    <Button className='mt-2'>{texts.backToOrder}</Button>
                </RestaurantLink>
            </div>
        }

        return <div className='container'>
            <h3>{texts.followInstructionsOnTerminal}</h3>
            <div className='center'>
                <img src="/assets/terminal.png" alt="terminal" className='mt-3' style={{ maxHeight: '40vh' }} />
            </div>
            <div className='center flex-column gap'>
                <AdvancedButton size='sm' processing={processing} className='mt-3' onClick={() => handleOrderCheck()}>{texts.iPaid}</AdvancedButton>
                {showWarning && <p className='text-warning m-0'>{texts.weFailedToVerify}</p>}
                <p className='text-small text-muted m-0'>{texts.thisPageWillAutoUpdate}</p>
            </div>
        </div>
    }

    return (
        <div className='streach text-primary'>
            <Menu hideLastOrder />
            <div className='center flex-column'>
                {renderContent()}
            </div>

            <CompanyFooter />
        </div>
    )
}
