import React, { useContext, useState, useEffect } from "react"
import { useDB } from "./FirebaseContext";
import Loading from "../components/Loading";
import { onSnapshot, query, where, limit, collection, getDocs } from "firebase/firestore";
import { fromFirebaseDocs } from "../utils/shared/firebase";
import Restaurant from "../models/Restaurant";
import { toast } from "react-toastify";
import { useText } from "./TextContext";
import { useNavigate } from "react-router-dom";
import config from "../utils/config";
import { DEFAULT_STYLES } from "../models/RestaurantStyles";
import { NETS_LIB_URLS } from "../constants/payments";
import { Collections } from "../constants/firestore";
import useQueryParam from "../hooks/useQueryParams";
import { QUERY_PARAMS } from "../utils/shared/constants";

const STYLE_TAG_ID = 'restaurantStyles';
const NETS_SCRIPT_TAG_ID = 'netsLib';
const DESCRIPTION_TAG_ID = 'description-meta-tag';

const restaurantContext = React.createContext<Restaurant>({} as any);

export function useRestaurant() {
    return useContext(restaurantContext)
}

export default function RestaurantProvider({ children }: any) {
    const db = useDB();
    const [restaurant, setRestaurant] = useState<Restaurant | null>(null)
    const text = useText();
    const queryParams = useQueryParam();
    const restuarantSysid = queryParams?.restaurant;
    const navigate = useNavigate();


    useEffect(() => {
        const finalStyle = {
            ...DEFAULT_STYLES,
            ...(restaurant?.styles || {})
        }

        const styleTag = document.getElementById(STYLE_TAG_ID);
        if (styleTag) {
            styleTag.remove();
        }

        const styleElement = document.createElement('style');
        styleElement.setAttribute('id', STYLE_TAG_ID);
        document.head.appendChild(styleElement);


        const rule: string = `
            :root {
                --primary: ${finalStyle.primary};
                --bs-primary: ${finalStyle.primary};
                --bg-primary: ${finalStyle.bgPrimary};
                --bg-secondary: ${finalStyle.bgSecondary};
                --font-primary: Roboto;
                --font-highlight: 'papercuts';
            }`


        const sheet = styleElement.sheet;
        sheet!.insertRule(rule, sheet!.cssRules.length);
        // eslint-disable-next-line
    }, [restaurant?.styles?.bgPrimary, restaurant?.styles?.bgSecondary, restaurant?.styles?.primary])

    useEffect(() => {
        if (restaurant?.features?.enabledPayments && restaurant?.features?.enabledPayments.includes('nets')) {
            const library = restaurant.features.netsTest ? NETS_LIB_URLS.TEST : NETS_LIB_URLS.PROD

            const existingScriptTag = document.getElementById(NETS_SCRIPT_TAG_ID);
            if (existingScriptTag) {
                existingScriptTag.remove();
            }

            const script = document.createElement('script');
            script.src = library;
            script.id = NETS_SCRIPT_TAG_ID;

            document.head.appendChild(script);
        }

    }, [restaurant?.features?.enabledPayments, restaurant?.features?.netsTest])

    useEffect(() => {
        const domain = window.location.hostname;
        const isCustomDomain = !config.baseDomains.includes(domain);

        const whereClause = isCustomDomain
            ? where('customDomain', '==', domain)
            : where('sysid', '==', restuarantSysid);


        const queryObject = query(
            collection(db, Collections.restaurants),
            whereClause,
            limit(1));
        return onSnapshot(queryObject, (querySnapshot) => {
            const restaurants = fromFirebaseDocs<Restaurant>(querySnapshot.docs);
            if (restaurants.length) {
                setRestaurant(restaurants[0]);
            } else {
                const q = query(collection(db, Collections.restaurants), where("status", "==", 'active'), limit(1));
                getDocs(q).then((querySnapshot) => {
                    const allRestaurants = fromFirebaseDocs<Restaurant>(querySnapshot.docs);
                    if (!allRestaurants.length) {
                        return;
                    }
                    navigate(`/?${QUERY_PARAMS.RESTAURANT}=${allRestaurants[0].sysid}`)
                });
            }
        },
            err => {
                console.error('Failed to get restaurant', err)
                toast.error(text.errorDefault)
            })
    }, [db, text.errorDefault, restuarantSysid, navigate]);

    useEffect(() => {
        if (restaurant?.name) {
            document.title = restaurant.name;

            const existingScriptTag = document.getElementById(DESCRIPTION_TAG_ID);
            if (existingScriptTag) {
                existingScriptTag.setAttribute('content', `Try our goodies at ${restaurant.name}`);
            }
        }
    }, [restaurant?.name])

    if (!restaurant) {
        return <div className="center flex-column h-100">
            <Loading />
            <p>Loading restaurant...</p>
        </div>
    }

    if (restaurant.status === 'disabled') {
        return <div className="center h-100 ">
            <div className="alert alert-warning">

                <p className='m-1'>{text.restaurantIsDisabled}</p>
            </div>
        </div>
    }


    return (
        <restaurantContext.Provider value={restaurant} >
            {children}
        </restaurantContext.Provider>
    )
}