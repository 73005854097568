import React, { useContext, useEffect, useMemo, useState } from "react"
import { Cart, CartItem } from "../models/Cart";
import Product from "../models/Product";
import Deal, { DealType } from "../models/Deal";
import { v4 as uuidv4 } from 'uuid';
import { calculateDealTrackerUpdate, calculateStampDealIncrease, generateItemId, generateItemName, maybeUpdateStampDealsOnCart, productPrice } from "../utils/shared/cart";
import { useAuth } from "./AuthContext";
import { LOCAL_STORAGE_KEYS } from "../constants/localStorage";
import useDeals from "../hooks/useDeals";
import UserDealTracker from "../models/UserDealTracker";

const CURRENT_CART_VERSION = 1;

interface CartContext {
    cart: Cart,
    adjustCount: (adjustment: number, product: Product, choices?: string[]) => void,
    updateItem: (newItem: CartItem, product: Product) => void
    clearCart: (id: string) => void
    resetCart: () => void
    addDeal: (deal: Deal) => void
    removeDeal: () => void
    updatePickupDate: (date: Date | null) => void
    updateTip: (tip: number) => void
};
const cartContext = React.createContext<CartContext>({} as any);

export function useCart() {
    return useContext(cartContext)
}



const persistCart = (cart: Cart) => {
    window.localStorage.setItem(LOCAL_STORAGE_KEYS.CART_KEY, JSON.stringify(cart));
}

const getOrInitCart = (): Cart => {
    const cartString = window.localStorage.getItem(LOCAL_STORAGE_KEYS.CART_KEY);
    const cashRegister = window.localStorage.getItem(LOCAL_STORAGE_KEYS.CARD_TERMINAL_NAME) || undefined;

    if (cartString) {
        const cart: Cart = JSON.parse(cartString);
        const correctVersion = cart.version === CURRENT_CART_VERSION;
        if (cart.id && correctVersion) {
            return cart;
        }
    }

    const emptyCart: Cart = {
        created: new Date(),
        items: [],
        deals: [],
        id: uuidv4(),
        pickupDate: null,
        tip: 0,
        cashRegister,
        version: CURRENT_CART_VERSION,
    }
    persistCart(emptyCart);

    return getOrInitCart();
}


export default function CartProvider({ children }: any) {
    const [cart, setCart] = useState<Cart>(getOrInitCart());
    const { currentUser, userDeals } = useAuth();
    const deals = useDeals();

    const updateCart = useMemo(() => (newCart: Cart) => {
        persistCart(newCart);
        setCart(newCart);
    }, []);

    const clearCart = (id: string) => {
        const currentCart = getOrInitCart();
        if (currentCart.id === id) {
            window.localStorage.removeItem(LOCAL_STORAGE_KEYS.CART_KEY);
            const newCart = getOrInitCart();
            setCart(newCart);
        }
    }

    useEffect(() => {
        const newUid = currentUser?.id || '';

        if (cart.uid !== newUid) {
            const newCart = {
                ...cart,
                uid: newUid
            }
            updateCart(newCart);
        }
    }, [currentUser, cart, updateCart])



    useEffect(() => {
        const allStampDeals = deals.filter(deal => deal.type === DealType.STAMP_CARD);

        const stampIncreases = calculateStampDealIncrease(cart, allStampDeals);

        let changed = false;
        let newDeals: Deal[] = [...cart.deals];
        for (const stampDeal of allStampDeals) {
            const increase = stampIncreases[stampDeal.id];

            const oldDealTracker: UserDealTracker = userDeals.dealTrackers[stampDeal.id] || {
                dealId: stampDeal.id,
                stampCount: 0,
                filledCards: 0,
            };

            const newDealTracker = calculateDealTrackerUpdate(oldDealTracker, stampDeal, increase);

            const freeItemCount = newDealTracker.filledCards - oldDealTracker.filledCards;

            const result = maybeUpdateStampDealsOnCart(newDeals, freeItemCount, stampDeal);
            if (result.changed) {
                newDeals = result.deals;
                changed = true;
            }
        }

        if (changed) {
            const someStampDeals = newDeals.some(deal => deal.type === DealType.STAMP_CARD);
            if (someStampDeals) {
                // Stamp deals can't combine with anything else
                newDeals = newDeals.filter(deal => deal.type === DealType.STAMP_CARD);
            }

            updateCart({
                ...cart,
                deals: newDeals
            })
        }
    }, [deals, cart, updateCart, userDeals, currentUser])

    const adjustCount = (number: number, product: Product, choicesPar?: string[]) => {
        const choices = choicesPar || [];
        const itemId = generateItemId(product.id, choices);
        let newItems = [...cart.items];
        let productInCart = newItems.find(item => item.itemId === itemId);

        if (!productInCart) {
            productInCart = {
                itemId,
                itemName: generateItemName(product, choices),
                choices,
                productId: product.id,
                itemPrice: productPrice(product, choices),
                quantity: 0,
                vatRate: product.vatRate,
            };
            newItems.push(productInCart);
        }
        productInCart.quantity += number;

        updateCart({
            ...cart,
            items: JSON.parse(JSON.stringify(newItems))
        })
    }

    const updateItem = (newItem: CartItem, product: Product) => {
        const oldItemId = newItem.itemId;
        const newItemId = generateItemId(newItem.productId, newItem.choices);

        let newItems: CartItem[] = [];

        if (newItem.quantity) {
            const existingItem = cart.items.find(item => item.itemId === newItemId);

            if (oldItemId === newItemId) {
                // Update the item if the IDs are the same
                newItems = cart.items.map(item => item.itemId === oldItemId ? newItem : item);
            } else if (existingItem) {
                // If the new item ID already exists, update the quantity
                existingItem.quantity += newItem.quantity;
                newItems = cart.items.filter(item => item.itemId !== oldItemId);
            } else {
                // If the new item ID does not exist, replace the old item with the new item
                newItem.itemId = newItemId;
                newItem.itemName = generateItemName(product, newItem.choices);
                newItem.itemPrice = productPrice(product, newItem.choices);
                newItems = cart.items.map(item => item.itemId === oldItemId ? newItem : item);
            }
        } else {
            // If the new item quantity is zero, remove the item
            newItems = cart.items.filter(item => item.itemId !== oldItemId);
        }

        updateCart({
            ...cart,
            items: newItems
        })
    }

    const addDeal = (deal: Deal) => {
        updateCart({
            ...cart,
            // always replacing for now
            deals: [deal]
        })
    }
    const removeDeal = () => {
        updateCart({
            ...cart,
            deals: []
        })
    }

    const resetCart = () => {
        window.localStorage.removeItem(LOCAL_STORAGE_KEYS.CART_KEY);
        setCart(getOrInitCart());
    }

    const updatePickupDate = (date: Date | null) => {
        updateCart({
            ...cart,
            pickupDate: date
        })
    }
    const updateTip = (tip: number) => {
        updateCart({
            ...cart,
            tip
        })
    }

    if (!cart) {
        return <></>
    }

    return (
        <cartContext.Provider value={{
            cart,
            adjustCount,
            updateItem,
            clearCart,
            addDeal,
            removeDeal,
            resetCart,
            updatePickupDate,
            updateTip,
        }} >
            {children}
        </cartContext.Provider>
    )
}
