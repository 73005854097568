import React from 'react'
import { useProducts } from '../../contexts/ProductsContext'
import ProductTile from './ProductTile';
import Product from '../../models/Product';
import ProductModal from '../modals/ProductModal';
import { useText } from '../../contexts/TextContext';

interface Props {
    className?: string
}

function UpsellProducts(props: Props) {
    const { allProducts } = useProducts();
    const [openedProduct, setOpenedProduct] = React.useState<Product | null>(null);
    const text = useText();

    const upsellProducts = allProducts.filter(product => product.isUpsell);

    if (!upsellProducts.length) {
        return null;
    }

    return <div className={`${props.className}`}>
        <h4>{text.upselHeader}</h4>
        <div className='checkout-upsell'>

            {upsellProducts.map(product => <ProductTile onClick={setOpenedProduct} key={product.id} product={product} />)}
        </div>
        {openedProduct && <ProductModal onClose={() => setOpenedProduct(null)} product={openedProduct} />}
    </div>


}

export default UpsellProducts
