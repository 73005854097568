import React, { useEffect, useRef, useState } from 'react'
import { printAmount } from '../../utils/shared/converters';

interface Props {
    amount: number
}

const ANIMATION_TIME = 300;

function AmountCountUp(props: Props) {
    const [currentAmount, setCurrentAmount] = useState(0);
    const previousAmountRef = useRef(0);


    useEffect(() => {
        const start = Date.now();
        const startAmount = previousAmountRef.current;
        const changeInAmount = props.amount - startAmount;

        const step = () => {
            const now = Date.now();
            const progress = Math.min((now - start) / ANIMATION_TIME, 1);
            setCurrentAmount(Math.floor(startAmount + progress * changeInAmount));
            if (progress < 1) {
                requestAnimationFrame(step);
            } else {
                previousAmountRef.current = props.amount;
            }
        };
        requestAnimationFrame(step);
    }, [props.amount]);

    return <>{printAmount(currentAmount)}</>;
}

export default AmountCountUp
