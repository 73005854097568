import React, { useContext, useEffect, useState } from "react"
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import useQueryParam from "../hooks/useQueryParams";
import { getRestaurantPath } from "../utils/restaurant";
import { useCart } from "./CartContext";
import { useRestaurant } from "./RestaurantContext";
import { useText } from "./TextContext";
import { LOCAL_STORAGE_KEYS } from "../constants/localStorage";


const TIMER_KEY = 'slicesize_public_counter';
// in seconds
const CHECK_INTERVAL = 1;
const REFRESH_INTERVAL = 150;
const WARNING_INTERVAL = 0;

interface PublicTerminalContext {
    isPublicTerminal: boolean
};
const publicTerminalContext = React.createContext<PublicTerminalContext>({ isPublicTerminal: false });

export function usePublicTerminal() {
    return useContext(publicTerminalContext)
}


export default function PublicTerminalProvider({ children }: any) {
    const { publicTerminal } = useQueryParam();
    const { resetCart } = useCart();
    const restaurant = useRestaurant();
    const navigate = useNavigate();
    const [showWarning, setShowWaring] = useState(false);
    const text = useText();
    const isPublicTerminal = !!publicTerminal || !!window.localStorage.getItem(LOCAL_STORAGE_KEYS.CARD_TERMINAL_NAME);


    useEffect(() => {
        if (!isPublicTerminal || !restaurant) {
            return;
        }

        const resetRefreshTime = () => {
            if (!isPublicTerminal) {
                return;
            }
            const newRefreshTime = new Date();
            newRefreshTime.setSeconds(newRefreshTime.getSeconds() + REFRESH_INTERVAL);
            window.localStorage.setItem(TIMER_KEY, newRefreshTime.toISOString());


            setShowWaring(false);
        }

        const check = () => {
            const refreshTimeString = window.localStorage.getItem(TIMER_KEY);

            if (refreshTimeString) {
                const date = new Date(refreshTimeString)
                const now = new Date();
                const diff = date.getTime() - now.getTime();

                if (diff < 0) {
                    window.localStorage.removeItem(TIMER_KEY);
                    resetCart();

                    navigate(getRestaurantPath(restaurant, '/'));
                    resetRefreshTime();
                } else if (diff <= WARNING_INTERVAL * 1000) {
                    setShowWaring(true);
                }
            } else {
                resetRefreshTime();
            }
        }

        const interval = setInterval(check, CHECK_INTERVAL * 1000)

        const clickHandler = () => {
            resetRefreshTime();
        }
        document.addEventListener("click", clickHandler)

        return () => {
            clearInterval(interval);
            document.removeEventListener("click", clickHandler)
        }
        // eslint-disable-next-line
    }, [isPublicTerminal, restaurant])

    return (
        <publicTerminalContext.Provider value={{ isPublicTerminal }} >
            {children}
            <Modal show={showWarning} className="modal fade" size='lg'>
                <div className="modal-content center p-3">
                    <p>{text.orderReset}</p>
                    <button className="btn btn-primary">{text.keepActive}</button>
                </div>
            </Modal>
        </publicTerminalContext.Provider>
    )
}
