import React from 'react';
import { initializeApp } from "firebase/app"
import { firebaseConfig } from '../utils/firebaseConfig';
import { getFirestore } from '@firebase/firestore';
import { connectFirestoreEmulator } from 'firebase/firestore';
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { connectStorageEmulator, getStorage } from "firebase/storage";
import config from '../utils/config';


const app = initializeApp(firebaseConfig);

export const FirebaseAppContext = React.createContext(app);

const db = getFirestore(app);
if (config.useEmulators) {
    connectFirestoreEmulator(db, 'localhost', 8080);
}

export const useDB = () => db;

const auth = getAuth(app);
if (config.useEmulators) {
    connectAuthEmulator(auth, "http://localhost:9099")
}

export const useFirebaseAuth = () => auth;

const storage = getStorage();
if (config.useEmulators) {
    connectStorageEmulator(storage, 'localhost', 9199);
}

export const useStorage = () => storage;
